<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <!-- Passa a prop fixedStore para o formulário -->
    <StateRenderer v-bind="user">
      <UserForm
        v-model="model"
        :disabled="isLoading"
        :fixed-store="isAdminFranchisee"
        @submit="onSubmit"
      />
    </StateRenderer>
  </Layout>
</template>

<script>
import { ROLES, STATUS } from '@/constants'
import { mapGetters } from 'vuex'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'
import Layout from '@/views/Layout'
import UserForm from '@/components/Users/UserForm.vue'
import StateRenderer from '@/components/StateRenderer'

export default {
  name: 'UserRegisterView',
  components: {
    Layout,
    UserForm,
    StateRenderer
  },
  mixins: [
    withAsyncAction({
      key: 'user',
      fetcher: {
        methodName: 'fetchUserById',
        handler: services.users.fetchUserById
      }
    }),
    withAsyncAction({
      key: 'createUserState',
      fetcher: {
        methodName: 'createUser',
        handler: services.users.createUser
      }
    }),
    withAsyncAction({
      key: 'updateUserState',
      fetcher: {
        methodName: 'updateUser',
        handler: services.users.updateUser
      }
    })
  ],
  data() {
    return {
      model: {
        // os campos iniciais do formulário
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        status: STATUS.ACTIVE.value,
        userStores: [
          {
            storeId: null,
            role: ROLES.EMPLOYER.value
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId', 'isAdminFranchisee']),
    breadcrumbs: () => [
      { text: 'Início', to: { name: 'HomeIndex' } },
      { text: 'Cadastros', to: { name: 'RegistersIndex' } },
      { text: 'Usuários', to: { name: 'UsersIndex' } },
      { text: 'Cadastro', to: { name: 'UserRegister' }, active: true }
    ],
    isLoading() {
      return this.createUserState.loading || this.updateUserState.loading
    }
  },
  async created() {
    const { id } = this.$route.params
    if (id) {
      this.model = await this.fetchUserById(id)
    } else {
      // Para novos usuários, setamos a loja corrente
      this.model.storeId = this.currentStoreId
      // Se o usuário autenticado for ADMIN_FRANCHISEE, fixa o userStore:
      if (this.isAdminFranchisee) {
        this.model.userStores = [
          {
            storeId: this.currentStoreId,
            role: ROLES.EMPLOYER.value
          }
        ]
      }
    }
  },
  methods: {
    async onSubmit(user) {
      const isInvalidUserStores = user.userStores.every(
        ({ storeId }) => storeId === null
      )
      if (isInvalidUserStores) {
        this.$root.$toast.warning('Informe loja e permissão corretamente')
        return
      }
      if (user.id) {
        await this.updateUser(user)
      } else {
        await this.createUser(user)
      }
      this.$root.$toast.success('Usuário salvo com sucesso')
      this.$router.push({ name: 'UsersIndex' })
    }
  }
}
</script>
