<template>
  <fieldset :disabled="disabled">
    <b-card no-body>
      <b-card-body>
        <b-form novalidate>
          <fieldset class="mb-3">
            <b-form-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Nome" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="validation.name"
                  />
                  <b-form-invalid-feedback v-if="$v.form.name.$invalid">
                    Informe o nome
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Usuário" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="validation.email"
                  />
                  <b-form-invalid-feedback v-if="$v.form.email.$invalid">
                    Informe um e-mail válido
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Senha" label-for="password">
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    :state="validation.password"
                    type="password"
                  />
                  <b-form-invalid-feedback v-if="$v.form.password.$invalid">
                    Informe uma senha
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group
                  label="Confirme a Senha"
                  label-for="password-confirmation"
                >
                  <b-form-input
                    id="password-confirmation"
                    v-model="form.passwordConfirmation"
                    :state="validation.passwordConfirmation"
                    type="password"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.passwordConfirmation.$invalid"
                  >
                    <span
                      v-if="!$v.form.passwordConfirmation.required"
                      class="d-block"
                    >
                      Informe novamente a senha
                    </span>
                    <span
                      v-else-if="!$v.form.passwordConfirmation.matchesPassword"
                      class="d-block"
                    >
                      As senhas devem ser iguais
                    </span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <hr />
            <!-- Lojas e Permissões -->
            <StoresFetcher>
              <template #default="{ data }">
                <fieldset>
                  <legend class="d-flex">
                    <span class="mr-3"> Lojas e Permissões </span>
                    <!-- <b-button
                      v-if="!fixedStore"
                      variant="info"
                      size="sm"
                      :disabled="isAddUserStoreDisabled(data)"
                      @click="handleAddUserStore"
                    >
                      <span class="fas fa-plus mr-1" /> Adicionar
                    </b-button> -->
                  </legend>

                  <div
                    v-for="(userStore, index) in form.userStores"
                    :key="userStore.storeId"
                  >
                    <UserStores
                      v-model="form.userStores[index]"
                      :fixed-store="fixedStore"
                      :stores="
                        fixedStore
                          ? getFixedStoreOptions(data, userStore.storeId)
                          : parseStoreOptions(data, userStore)
                      "
                      :roles="
                        fixedStore ? getFixedRoleOptions() : getRolesOptions()
                      "
                      :validation="$v.form.userStores"
                      :validate="$v.form.$dirty"
                      :enable-remove="!fixedStore && index > 0"
                      :disabled="fixedStore"
                      @removeUserStore="handleRemoveUserStore"
                    />
                  </div>
                </fieldset>
              </template>
            </StoresFetcher>
            <hr />
            <StatusInput
              v-model="form.status"
              :validation="validation.status"
              :invalid="$v.form.status.$invalid"
            />
          </fieldset>
        </b-form>
      </b-card-body>
    </b-card>

    <div class="mt-3 d-flex gap-1 justify-content-end">
      <b-button variant="outline-secondary" @click="handleCancel('UsersIndex')">
        Cancelar
      </b-button>
      <b-button variant="success" @click="handleSubmit"> Salvar </b-button>
    </div>
  </fieldset>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { ROLES } from '@/constants'
import withFormValidation from '@/mixins/withFormValidation'
import StatusInput from '@/components/StatusInput'
import StoresFetcher from '@/components/Stores/StoresFetcher'
import UserStores from '@/components/Users/UserStores'
import { isEmpty } from 'lodash/fp'

export default {
  name: 'UserForm',
  components: {
    StatusInput,
    StoresFetcher,
    UserStores
  },
  mixins: [
    withFormValidation({
      fields: [
        'userStores',
        'name',
        'email',
        'password',
        'passwordConfirmation',
        'status'
      ]
    })
  ],
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // Prop para identificar usuário Franqueado Admin – quando true, a loja e a permissão ficarão fixas
    fixedStore: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    form: {
      name: { required },
      email: { required },
      password: {
        required: (value, { id }) => isEmpty(id)
      },
      passwordConfirmation: {
        required: (value, { id }) => isEmpty(id),
        matchesPassword: (value, { password }) => value === password
      },
      userStores: { required },
      status: { required }
    }
  },
  data() {
    return {
      roles: ROLES
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    // Retorna as opções de loja fixas: somente a loja cadastrada, com nome no label
    getFixedStoreOptions(data, storeId) {
      const store = data.find((s) => s.id === storeId)
      return store ? [{ code: store.id, label: store.name }] : []
    },
    // Retorna as opções de permissão fixas: somente EMPLOYER, com o text definido
    getFixedRoleOptions() {
      return this.roles.EMPLOYER
    },
    getRolesOptions() {
      return Object.values(this.roles).map((role) => ({
        code: role.value,
        label: role.text
      }))
    },
    handleAddUserStore() {
      this.form.userStores = [
        ...this.form.userStores,
        {
          storeId: null,
          role: this.roles.EMPLOYER.value
        }
      ]
    },
    handleRemoveUserStore({ storeId }) {
      this.form.userStores = this.form.userStores.filter(
        (userStore) => userStore.storeId !== storeId
      )
    },
    isAddUserStoreDisabled(stores) {
      return this.form.userStores.length === stores.length
    },
    parseStoreOptions(stores, { storeId }) {
      const userStoresIds = this.form.userStores.map(({ storeId }) => storeId)
      return stores
        .filter(({ id }) => !userStoresIds.includes(id) || id === storeId)
        .map(({ id, name }) => ({
          code: id,
          label: name
        }))
    },
    handleCancel(routeName) {
      this.$router.push({ name: routeName })
    },
    handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.$emit('submit', this.form)
    }
  }
}
</script>
