<template>
  <div class="d-flex">
    <div lg="6" md="6" sm="12" class="flex-1 mr-2">
      <b-form-group label="Loja" label-for="store">
        <BaseSelect
          v-model="form.storeId"
          :options="stores"
          :reduce="onReduceStore"
          :clearable="false"
          :validation="$v.form.storeId"
          :disabled="fixedStore"
        />
        <b-form-invalid-feedback v-if="$v.form.storeId.$invalid">
          Informe a loja
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <div lg="6" md="6" sm="12" class="d-flex flex-1">
      <b-form-group class="flex-1 mr-2" label="Permissão" label-for="role">
        <div class="d-flex">
          <b-form-select
            v-model="form.role"
            :state="validation.role"
            :options="rolesOptions"
            class="flex-1 mr-2"
            :disabled="fixedStore"
          />
          <b-button
            v-if="enableRemove"
            variant="danger"
            @click="$emit('removeUserStore', form)"
          >
            <span class="fas fa-times" />
          </b-button>
        </div>
        <b-form-invalid-feedback v-if="$v.form.role.$invalid">
          Informe a permissão
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BaseSelect from '@/components/BaseSelect'
import withFormValidation from '@/mixins/withFormValidation'
import { ROLES } from '@/constants'

export default {
  name: 'UserStore',
  components: {
    BaseSelect
  },
  mixins: [
    withFormValidation({
      fields: ['storeId', 'role']
    })
  ],
  validations: {
    form: {
      storeId: { required },
      role: { required }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    stores: {
      type: Array,
      default: () => []
    },
    validate: {
      type: Boolean
    },
    enableRemove: {
      type: Boolean
    },
    fixedStore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    rolesOptions() {
      if (this.fixedStore) {
        return [ROLES.EMPLOYER]
      }
      return Object.values(ROLES)
    }
  },
  watch: {
    validate(value) {
      if (value) {
        this.$v.form.$touch()
      }
    }
  },
  methods: {
    onReduceStore({ code }) {
      return code
    }
  }
}
</script>
